body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.min-h-screen {
  min-height: 100vh;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-purple-500 {
  --tw-gradient-from: #9f7aea;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(159, 122, 234, 0));
}

.to-pink-500 {
  --tw-gradient-to: #ed64a6;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.font-bold {
  font-weight: 700;
}

.text-white {
  color: #ffffff;
}

.mb-4 {
  margin-bottom: 1rem;
}

.bg-gray-800 {
  background-color: #2d3748;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.overflow-hidden {
  overflow: hidden;
}

.absolute {
  position: absolute;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bg-black {
  background-color: #000000;
}

.bg-opacity-75 {
  opacity: 0.75;
}

.z-10 {
  z-index: 10;
}

.text-center {
  text-align: center;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.mt-4 {
  margin-top: 20px;
}

.px-4 {
  padding-left: 20px;
  padding-right: 20px;
}

.py-2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.bg-blue-500 {
  background-color: #4299e1;
}

.hover\:bg-blue-600:hover {
  background-color: #3182ce;
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ml-4 {
  margin-left: 0;
}

.p-4 {
  padding: 20px;
}

.text-2xl {
  font-size: 24px;
  line-height: 2rem;
}

@media (max-width: 600px) {
  body {
    margin: 10px;
    touch-action: pan-x pan-y;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .fixed.bottom-4.left-1\/2.transform.-translate-x-1\/2.flex.space-x-2.md\:hidden {
    display: flex;
    justify-content: space-around;
    bottom: 10px;
    width: 100%;
  }

  .fixed.bottom-4.left-1\/2.transform.-translate-x-1\/2.flex.space-x-2.md\:hidden button {
    padding: 10px;
    font-size: 16px;
    background-color: #4299e1;
    color: #ffffff;
    border-radius: 0.5rem;
  }

  .title {
    color: red;
  }
}